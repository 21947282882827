const apiConfigs = {
  "regional": {
    "apiHost": "https://api-dev.mit.edu",
    "paths": {
      "apiPath": "voip-v2",
      "digitalId": "digital-id-v1"
    }
  },
  "global": {
    "apiHost": "https://voip-v2.atlas-apps-dev.mit.edu/api",
    "paths": {
      "apiPath": "voip/v2",
      "digitalId": "digital-id/v1"
    }
  }
};
export default apiConfigs;