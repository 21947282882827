const appConfig = {
  "name": "voip-v2-main",
  "version": "0.1.0",
  "stage": "dev",
  "deployThisBuild": false,
  "okta": {
    "issuer": "https://okta.mit.edu/oauth2/aus6shg1319alZcDf697",
    "clientId": "5c1evfgmlntprrg0493svlunli",
    "redirectUris": [
      "https://voip-v2-main.atlas-apps-dev.mit.edu",
      "https://voip-v2.atlas-apps-dev.mit.edu"
    ],
    "postLogoutRedirectUris": [
      "https://voip-v2-main.atlas-apps-dev.mit.edu/logout",
      "https://voip-v2.atlas-apps-dev.mit.edu/logout"
    ],
    "scopes": [
      "address",
      "digital-id/search",
      "digital-id/user",
      "email",
      "offline_access",
      "openid",
      "phone",
      "profile",
      "voip/user"
    ]
  },
  "amplify": {
    "userPoolId": "us-east-1_14cFcpuS8",
    "userPoolWebClientId": "5c1evfgmlntprrg0493svlunli",
    "oauth": {
      "domain": "atlas-auth-dev.mit.edu",
      "scope": [
        "digital-id/search",
        "digital-id/user",
        "openid",
        "profile",
        "voip/user"
      ],
      "redirectUris": [
        "https://voip-v2-main.atlas-apps-dev.mit.edu",
        "https://voip-v2.atlas-apps-dev.mit.edu"
      ],
      "postLogoutRedirectUris": [
        "https://voip-v2-main.atlas-apps-dev.mit.edu/logout",
        "https://voip-v2.atlas-apps-dev.mit.edu/logout"
      ],
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  }
};
export default appConfig;